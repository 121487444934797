<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl">
        <div class="row">
            <div class="col-12 d-flex justify-content-end order-1">
                <img src="imgs/logo.svg" class="img-fluid mb-3" alt="logo"/>  
            </div>
            <div class="col-12 col-lg-3 position-relative order-4 order-lg-2">
                 <img src="imgs/bagoly.png" class="position-lg-absolute owl" alt="logo"/>   
            </div>
            <div class="col-12 col-lg-5 d-md-flex justify-content-center flex-column py-lg-5 py-0 order-2">
                <div class="py-md-5 py-0">
                    <h1 class="mt-4 mt-md-0 text-yellow bg-black">Gratulálunk!</h1>              
                    <p class="text-black text-left display-3 bg-yellow font-secondary">Érvényes pályázat esetén megnyerheted<br/><br/>
                        <span class="text-uppercase winner-page__hero__title" v-if="winnerType == 'napi'">Napi nyereményünket!</span>
                        <span class="text-uppercase winner-page__hero__title" v-if="winnerType == 'heti'">Heti nyereményünket!</span>
                        <span class="text-uppercase winner-page__hero__title" v-if="winnerType == 'fodij'">Fődíjunkat!</span>
                    </p>
                <!--<img v-if="winnerType == 'napi'" src="/imgs/daily-brush.svg" alt="napi nyememény" class="img-fluid" />
                <img v-if="winnerType == 'heti'" src="/imgs/weekly-brush.svg" alt="heti nyeremény" class="img-fluid" />
                <img v-if="winnerType == 'fodij'" src="/imgs/winner-brush.svg" alt="fődíj" class="img-fluid" />  -->         
                </div>
            </div>      
          
            <div class="col-12 col-lg-3 offset-lg-1 align-self-center pb-md-5 pb-0 order-3">
                <div class="winnerType-bgr text-center py-md-5 py-0">
                    <img v-if="winnerType == 'napi'" src="imgs/nyeremeny-napi-mobile.png" alt="napi nyememény" class="img-fluid" width="250"/>
                    <img v-if="winnerType == 'heti'" src="imgs/nyeremeny-hetente-mobile.png" alt="heti nyeremény" class="img-fluid" width="250"/>
                    <img v-if="winnerType == 'fodij'" src="imgs/nyeremeny-fodij-mobile.png" alt="fődíj" class="img-fluid" width="250"/>
                </div>
            </div>
        </div>   
    </div>    
  </div>
  <Ribbon/>
</div>
</template>

<script>
import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        Ribbon
    },
    props:['winnerType']
}
</script>