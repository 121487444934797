<template>
  <div class="winner-page-main" id="winnerFormWrap">    
    <FormExpiredAndRegistered
        v-if="formExpired || alreadyRegistered || formDisabled || uploadedWinner === true"
        :formExpired="formExpired"
        :alreadyRegistered="alreadyRegistered"
        :formDisabled="formDisabled"
        :winnerType="winnerType"
    />

    <div v-else class="winner-page__main-content">
      <CodeFillingWinnerformHero :winnerType="winnerType" :needBtn="true" />
      
      <div
          class="winner-page__codefilling-form-background second-background-inner page-block"
          :class="{ 'congrat-background-space': uploadedWinner === true }"
      >
        <h2 class="text-center text-secondary pb-5">Kérjük, add meg az adataid!</h2>        
        
          <div class="container-xl">
            <div class="row">
              <div class="col-md-12 col-lg-10 offset-lg-1">              
                <div class="codefilling-form" style="border-radius:20px">                
                    <validation-observer
                        v-if="formStatus === false"
                        v-slot="{ handleSubmit }"
                        tag="div"
                        ref="winnerFormObserver"
                    >
                      <form
                          class="form form--codefilling"
                          @submit.prevent="handleSubmit(formSubmit)"
                          id="winnerForm"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="winner-codefilling__form px-3 py-5 p-md-5">             
                              <div class="form-group row">
                                <div class="surname-row input--info col-12 col-md-6">                               
                                  <CustomInput
                                    label="Vezetéknév"
                                    name="last_name"                                                                        
                                    v-model="form.last_name"
                                    disabled
                                  />  
                                </div>
                                <div class="forname-row input--info col-12 col-md-6">                      
                                   <CustomInput
                                    label="Keresztnév"
                                    name="last_name"                                                                        
                                    v-model="form.first_name"
                                    disabled
                                  />  
                                </div>
                                <div class="form-row col-12 col-md-6">
                                    <CustomInput
                                        label="E-mail cím"
                                        name="email"
                                        v-model="form.email"
                                        disabled                     
                                    />
                                </div>
                              
                              <div class="form-group mb-0 form-row col-12 col-md-6">
                                 
                                  <div class="form-row phone-wrap phone-row">
                                    <div class="form-label">Telefonszám<span class="label-red"> * </span></div>
                                    <div class="d-flex w-100 align-items-start">
                                        <span class="me-2 d-block phone-prefix">+36</span>
                                        <CustomInput
                                            class="w-100"
                                            label=""
                                            name="phone"                                            
                                            v-model="form.phone"
                                            tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                            v-mask="['##-###-####', '#-###-####']"
                                            rules="required"
                                        />
                                    </div>
                                </div>
                            
                               
                              </div>
                                  <div
                                      class=" form-group zip-row input--info col-12 col-md-6">
                                      <!-- zip  -->                                 
                                    <div class="col-12">
                                      <CustomInput
                                          label="Irányítószám"
                                          type="text"
                                          name="zip"
                                          placeholder="Irányítószám"                                          
                                          v-model="form.zip"
                                          rules="required|min:4"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group input--info city-row col-12 col-md-6">                                 
                                      <div class="col-12">
                                        <CustomInput
                                            label="Település"
                                            type="text"
                                            name="city"
                                            placeholder="Település"                                          
                                            v-model="form.city"
                                            rules="required"
                                        />
                                      </div>
                                </div>
                                  <div class="input--info street-row col-12 col-md-6">
                                        <CustomInput
                                            label="Utca"
                                            type="text"
                                            name="street"
                                            placeholder="Utca"                                          
                                            v-model="form.street"
                                            rules="required"
                                        />
                                  </div>
                                  <div class="input--info house-row col-12 col-md-6">
                                    <CustomInput
                                            label="Házszám, emelet, ajtó"
                                            type="text"
                                            name="house"
                                            placeholder="Házszám, emelet, ajtó"                                          
                                            v-model="form.house"
                                            rules="required"
                                        />
                                  </div>                                                                                 
                            </div>
                            <!-- BLOKK FELTÖLTÉS -->
                            <FileUpload v-if="needFileUpload" :imageApiError="imageApiError" @changeFile="changeFile" :imageError="imageError"  />

                            <div class="mt-3 mb-4 text-center d-flex">                                
                                <span class="text-red fw-bold">*</span>
                                <span>&nbsp;</span> 
                                <span><p class="m-0 font-secondary">A csillaggal jelölt mezők kitöltése szükséges.</p></span>
                            </div>
                          <!-- info text -->
                            <div class="info-block font-secondary d-flex align-items-center form__border p-3">                      
                                
                                <img src="imgs/exclamation-circle.svg" alt="mark" class="img-fluid me-2" /> 
                                <p class="m-0 text-black font-secondary">Kérjük, olyan címet adj meg, ahol át tudod venni a nyereményed! A vásárlást igazoló eredeti blokkot <b>2023. január 07-ig</b> őrizd meg!</p>
                            </div>

                              <!-- SUBMIT button                               -->
                              <div class="d-flex justify-content-center mt-4">
                                    <button type="submit" class="btn btn-secondary mr-0 btn-primary--large">
                                        adatok elküldése         
                                    </button>
                              </div>
                            </div>
                            <div>

                            </div>
                          </div>
                        </div>

                
                      </form>
                    </validation-observer>                
                </div>
              </div>
            </div>
          </div>        
      </div>      
    </div>    
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
// import $ from "jquery";
/* eslint-disable */
import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import FormExpiredAndRegistered from "@/components/FormExpiredAndRegistered";
import CustomInput from '@/components/base/CustomInput.vue'
import FileUpload from '@/components/FileUpload.vue'

setInteractionMode("eager");
// Add a validation rules

extend("email", {
  ...email  
});
extend("required", {
  ...required  
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"]  
});


export default {
  data() {
    return {
      needFileUpload:true,
      form: {
        last_name: "",
        first_name: "",
        email: "",
        zip: "",
        city: "",
        phone: "",
        street: "",
        house: "",
        hash: this.$route.query.hash,
        hash2: this.$route.query.hash2,
        receipt: null,
        receipt2: null,
        receipt3: null,
        receipt4: null,
        receipt5: null,        
      },      
      // maxFileUploadNum:2,
      formStatus: false,
      alreadyRegistered: false,
      responseErrors: null,
      triedFillForm: false,
      uploadedWinner: false,
      winnerType: null,
      uploadedError: false,
      imageError: false,
      // uploadedFiles: [],
      formExpired: false,
      formDisabled: false,
      formIsSubmitting: false,
      imageApiError:null,
    };
  },
  components: {
    FormExpiredAndRegistered,
    ValidationProvider,
    ValidationObserver,
    CodeFillingWinnerformHero,
    CustomInput,
    FileUpload 
  },
  directives: {
    mask,
  },

  methods: {
    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },   
    // handleFilesUpload() {
    //   let _this = this;
    //   //remove the error msg if there was
    //   this.$refs.uploadederror.innerHTML = "";

    //   let uploadedItem = this.$refs.receipt.files[0];

    //   //check file size 5Mb max
    //   let maxFileSize = 1024 * 1024 * 5;
    //   let fileSize = uploadedItem.size;

    //   //check file fomat
    //   // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

    //   //check file size - max 2Mb
    //   if (fileSize > maxFileSize) {
    //     this.$refs.uploadederror.innerHTML = _this.tr('file-upload-big-size')
    //         // "Túl nagy a fájl mérete (max. 5Mb)";
    //     // } else if (isGoodExtension == false) {
    //     //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
    //   } else {
    //     if (_this.uploadedFiles.length < parseInt(_this.maxFileUploadNum)) {
    //       //max 5 item could be uploaded

    //       let nameIndex = _this.uploadedFiles.find(
    //           (item) => item.name == uploadedItem.name
    //       );

    //       if (nameIndex == undefined) {
    //         _this.uploadedFiles.push(uploadedItem);
    //       } else {
    //         this.$refs.uploadederror.innerHTML = "Megegyező fájl név."
    //       }
    //     } else {
    //       _this.$refs.uploadederror.innerHTML = "Nem tölthető fel több fájl."
    //     }
    //   }

    //   this.fillReceipts();

    //   //set the default state for the input type=file
    //   this.$refs.receipt.value = ""

    //   if (this.form.receipt != null) {
    //     this.imageError = false;
    //   }
    // },
    // fillReceipts() {
    //   let _this = this;

    //   _this.form.receipt = null;
    //   _this.form.receipt2 = null;
    //   // _this.form.receipt3 = null;
    //   // _this.form.receipt4 = null;
    //   // _this.form.receipt5 = null;

    //   this.uploadedFiles.forEach(function (item, i) {
    //     if (i == 0) {
    //       _this.form.receipt = item;
    //     } else if (i == 1) {
    //       _this.form.receipt2 = item;
    //     }
    //     // } else if (i == 2) {
    //     //   _this.form.receipt3 = item;
    //     // } 
    //     // else if (i == 3) {
    //     //   _this.form.receipt4 = item;
    //     // } else if (i == 4) {
    //     //   _this.form.receipt5 = item;
    //     // }
    //   });
    // },
    // delUploadedFile(delItem) {
    //   let _this = this
    //   let delItemIndex = this.uploadedFiles.indexOf(delItem);

    //   if (delItemIndex != -1) {
    //     _this.uploadedFiles.splice(delItemIndex, 1);
    //   }

    //   this.$refs.uploadederror.innerHTML = "";
    //   this.fillReceipts();
    // },
      changeFile( form){
            console.log("changeFile", form)
            this.form.receipt = form.receipt
            this.form.receipt2 = form.receipt2
            this.form.receipt3 = form.receipt3
            this.form.receipt4 = form.receipt4
            this.form.receipt5 = form.receipt5
        },
    getFormData(){
          let _this = this
          let formData = new FormData();
          
          if (this.form.phone){
                this.form.phone = '+36-' + this.form.phone
          }
          formData.append("phone", _this.form.phone);
          formData.append("zip", _this.form.zip);
          formData.append("city", _this.form.city);
          formData.append("street", _this.form.street);
          formData.append("house", _this.form.house);
          formData.append("hash", _this.form.hash);
          formData.append("hash2", _this.form.hash2);
          formData.append("receipt", _this.form.receipt);
          formData.append("receipt2", _this.form.receipt2);
          formData.append("receipt3", _this.form.receipt3);
          formData.append("receipt4", _this.form.receipt4); 
          formData.append("receipt5", _this.form.receipt5);         

          return formData
    },
    formSubmit() {
      var _this = this;      
      
      //if the form needs file upload
      if (this.needFileUpload){
        this.imageApiError = false

        //kötelező blokkot feltölteni
        //console.log(_this.form.receipt);
        if (!_this.form.receipt) {
          _this.imageError = true;
          return;
        }
      }            

      if (!_this.formIsSubmitting) {
        _this.formIsSubmitting = true      

        let formContent = null
        if (this.needFileUpload){
          formContent = _this.getFormData()
        } else {
          formContent = _this.form
        }

        this.post("step2/upload", formContent, {})
            .then((response) => {
              var _this = this;              
              console.log(_this.response);
              if (!response.data.status || response.data.status === false) {                                
                console.log("response false")
                _this.goToError(error) 

              } else if (response.data.status) {
                console.log('success post')
                //success
                _this.formStatus = true;
                _this.uploadedWinner = true;
                // scroll to success screen's top
              
                  let element = document.querySelector('#success-data-upload')
                  if (element){
                      _this.scrollToElement(element, 20)       
                  }
              }

              _this.formIsSubmitting = false
              
            }).catch(function (error) {
              console.log('catch post')
              console.log(error);
              _this.formIsSubmitting = false

              let errorsArray = Object.entries(error.response.data.error)
              for (const [errorName, errorValue] of errorsArray) {
                  if (errorName === "receipt" || errorName === "receipt2"){
                      _this.imageApiError = true
                      
                  } else {
                        
                        // GTMcategory = 'egyeb_hiba'                                                        
                  }
              }

              _this.goToError(error)                        

            });
      } else {
        console.log('double form submit')
      }


    }, 
    goToError(err){            
        let _this = this
        //scroll to the first error
        this.setErrorMsgs(err, 'winnerFormObserver').then(()=>{                               
          let firstError = document.querySelector('#winnerForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }          
        })                                       
    },
  },  
  created() {
    let _this = this

    // for safety reasons - logged out the user   
    //TODO: csak akkor léptessük ki ha nem egyezik meg a user  
    localStorage.setItem("token", false);    

    this.get(            
            "step2/hash?hash=" +
            this.$route.query.hash +
            "&hash2=" +
            this.$route.query.hash2
        )
        .then((response) => {
          // console.log(response.data)

          if (response.data.status === false) {
            //not winner or not good hash
            console.log('error')
            this.$router.push({name: "APCodeUpload"});
          } else if (response.data.status.toLowerCase() == "ok") {
            //a winner opened the page
            if (!response.data.winner) {
              // console.log("nem nyertes")
              this.$router.push({name: "APCodeUpload"});
            } else {
              
              if (parseInt(response.data.winner.step2_done) === 1 ) {
            
                _this.formStatus = true;
                _this.alreadyRegistered = true;
              }
              
              _this.winnerType = response.data.winner.type;
              _this.form.email = response.data.winner.email;
              _this.form.first_name = response.data.winner.first_name;
              _this.form.last_name = response.data.winner.last_name;
              //user data
              _this.form.street = response.data.winner.street
              _this.form.house = response.data.winner.house
              if (response.data.winner.phone){
                  _this.form.phone = response.data.winner.phone.slice(3)
              }
              
              _this.form.city = response.data.winner.city
              _this.form.zip = response.data.winner.zip
            }
          } else if (response.data.status.toLowerCase() == "expired" && parseInt(response.data.winner.step2_done) === 1) {
            //the time is over than 5 days
            this.formExpired = true;
            _this.winnerType = response.data.winner.type;
          } else if (response.data.status.toLowerCase() == "expired" && parseInt(response.data.winner.step2_done) === 1) {
            //the time is over than 5 days, but sent data
            this.alreadyRegistered = true;
            _this.winnerType = response.data.winner.type;
          } else if (response.data.status.toLowerCase() == "disabled") {
            // console.log("status disabled");
            this.formDisabled = true;
            _this.winnerType = response.data.winner.type;
          }
        }).catch(function(){       
          //the url isn't valid  
          _this.$router.push({name: "APCodeUpload"});
        })
  } 
};
</script>
